import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Layout from './layout/Layout';

const Updates = () => {

    return (
        <Layout>
                <div className="legacy-home-container">
                    <div className="home-content">
                        
                        <div className='legacy-title desktop'>
                            <a target="_blank"  
                            href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                            </a>
                            <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                            width="1" 
                            height="1" 
                            border="0" 
                            alt="" 
                            style={{border:'none !important', margin:'0px !important'}} />
                        </div>

                        <div className='legacy-title mobile'>
                            <a target="_blank"  
                            href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                            </a>
                            <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                            width="1" 
                            height="1" 
                            border="0" 
                            alt="" 
                            style={{border:'none !important', margin:'0px !important'}} />
                        </div>
                    </div>
                </div>

                <h3>Updates</h3>
                <p>Updates to the web site will be posted here.  Check back frequently!</p>

                <h5>01/14/2021</h5>
                A new <Link to='/shout'>Shout Box</Link> goes up on the site.  This is experimental, and is using the new architecture of the new site under development.  Please stop by and say hi!

                <h5>12/29/2020</h5>
                World of Anime - the social networking site goes on hiatus.  A new experimental sandbox web site is put up in its place.
        </Layout>
    )
};

export default Updates;