import React, {Fragment, useEffect, useState} from 'react';
import Layout from './layout/Layout';
import Shout from './Shout';

const ShoutBox = () => {


    return (
        <Layout>
                <div className="legacy-home-container">
                    <div className="home-content">
                        
                        <div className='legacy-title desktop'>
                            <a target="_blank"  
                            href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                            </a>
                            <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                            width="1" 
                            height="1" 
                            border="0" 
                            alt="" 
                            style={{border:'none !important', margin:'0px !important'}} />
                        </div>

                        <div className='legacy-title mobile'>
                            <a target="_blank"  
                            href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                            </a>
                            <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                            width="1" 
                            height="1" 
                            border="0" 
                            alt="" 
                            style={{border:'none !important', margin:'0px !important'}} />
                        </div>
                    </div>
                </div>

                <Shout />
        </Layout>
    )
};

export default ShoutBox;