import React, { Fragment } from 'react';
import flags from '../../config/flags';
import { Link } from 'react-router-dom';
import Alert from './Alert';
import Header from './Header';

const Layout = (props) => {
    return (
        <Fragment>
            <Header />
            <div className='container'>
                {flags.socialSite &&
                    <Link to='/'>
                        <img 
                            className='container--img'
                            src='/images/classic-header.jpg'
                            alt='World of Anime' 
                        />
                    </Link>
                }

                <main>
                    <Alert />
                    {props.children}
                </main>
            </div>

        </Fragment>
    )
};

export default Layout;