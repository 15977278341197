import React, { Fragment, useState, useEffect } from 'react';
import flags from '../config/flags';
import { connect } from 'react-redux';
import { setAlert } from '../actions/alert';
import PropTypes from 'prop-types';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { login } from '../actions/auth';
import Layout from './layout/Layout';

const Login = ({ setAlert, login, auth: { isAuthenticated, loading } }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        submitted: false
    });

    useEffect(() => {
        setFormData({ email: '', password: '', submitted: false });
    }, [isAuthenticated]);

    let history = useHistory();

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        setFormData({...formData, submitted: true});
        e.preventDefault();
        const loginPromise = login(email, password);

        loginPromise.then((loginState) => {
            if (!loginState.loggedIn) {
                setFormData({...formData, submitted: false});
                setAlert('Invalid credentials.', 'danger', 5000);
            }

            if (loginState.loggedIn) {
                history.goBack();
            }    
        });
    };

    const LoginForm = (
        <Layout>
            <h1>Sign In</h1>
            <p>Sign Into Your Account</p>
            <form className="form" onSubmit={e => onSubmit(e)}>

            <div className="form__element">
                <label id="email-label" htmlFor="email">Email</label>
                <input 
                type="email" 
                placeholder="Email Address" 
                name="email" 
                value={email}
                onChange={e => onChange(e)}
                required
            />
            </div>
            <div className="form__element">
                <label id="password-label" htmlFor="password">Password</label>
                <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={e => onChange(e)}
                />
            </div>
            <button disabled={formData.submitted} className="btn btn-primary" onClick={onSubmit}>Login</button>
            </form>

            <Link to='/login/forgot'>
                Forgot password?
            </Link>

            {!flags.allowCreateAccount && <Fragment>
                <h2>Want an account?</h2>
                <p>World of Anime is currently in beta, and so we are not allowing you to create your own account.
                If you would like to help beta test, and understand that you may encounter bugs, you can e-mail me
                at meeko (at) worldofanime.com to ask for an account.  Please let me know the e-mail address you would
                like to use for your account.  Since I am limiting the number of accounts for now, I can not 
                guarantee you an account.
                </p>
            </Fragment>}
        </Layout>
    )

    return (
        <Fragment>
            { !loading && (
                <Fragment>
                {isAuthenticated ? (<Redirect to={`/`} />) : (<Fragment>
                        {LoginForm}
                    </Fragment>)}
                </Fragment>
            ) }
        </Fragment>
    )
    
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.object
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert, login })(Login);