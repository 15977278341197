import React, {Fragment, useEffect, useState} from 'react';
import flags from '../config/flags';
import { Link } from 'react-router-dom';
import Layout from './layout/Layout';
import PropTypes from 'prop-types';
import { getNewMembers } from '../actions/user';
import ProfileBasic from './profile/ProfileBasic';
import { connect } from 'react-redux';

const Home = ( { getNewMembers,
                isAuthenticated, 
                loading}) => {


    const [newMembers, setNewMembers] = useState(null);

    useEffect(() => {
        if (!newMembers) {
            getNewMembers().then((members) => {
                setNewMembers(members);
            });
        }

    }, []);



    return (
        <Layout>
            {flags.socialSite && !isAuthenticated && !loading && <Fragment>
                <img src="/images/anime_hi.jpg" alt="Welcome to World of Anime" />
                    <p>Hi there! It doesn't look like you're a member of World of Anime yet. (Either that or you aren't <Link to="/login">logged in</Link>)</p>
                    {flags.allowCreateAccount && <Fragment>
                        <p>If you are not a member, we'd love to have you! Please join the world's best anime community site, and start having fun today!</p>
                        <p><Link to="/join">Yes, I'd love to join!</Link></p>
                    </Fragment>}
                </Fragment>}
                {flags.socialSite &&
                    <div className="home-container">
                        <div className="home-content">
                            <h2>Welcome to World of Anime!</h2>
                            <p>World of Anime is an anime community site custom created just for anime fans, and the best place for meeting new friends who all share your love of anime!</p>
                        </div>
                        <div className="home-welcome-image">
                            <img src="/images/welcome.jpg" />
                        </div>                    
                    </div>
                }

                {flags.socialSite && 
                    <div className="home-container">
                        <div className="home-content">
                            <h3>Please take a moment to welcome our newest members!</h3>
                            {newMembers && newMembers.length > 0 && <Fragment>
                                {newMembers.map(user => (
                                    <ProfileBasic key={user._id} username={user.username} profile_pic={user.avatar} />
                                ))}
                                </Fragment>}
                        </div>
                    </div>
                }

                {!flags.socialSite && <Fragment>
                        <div className="legacy-home-container">
                            <div className='legacy-title desktop'>
                                <a target="_blank"  
                                    href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                        <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                                    </a>
                                    <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                                    width="1" 
                                    height="1" 
                                    border="0" 
                                    alt="" 
                                    style={{border:'none !important', margin:'0px !important'}} />
                            </div>

                            <div className='legacy-title mobile'>
                                <a target="_blank"  
                                href="https://www.amazon.com/gp/product/B00KMZUCVA/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KMZUCVA&linkCode=as2&tag=worldofanime09-20&linkId=8121b0ccb04dc31c456e7563d7565430">
                                    <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00KMZUCVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=worldofanime09-20" />
                                </a>
                                <img src="//ir-na.amazon-adsystem.com/e/ir?t=worldofanime09-20&l=am2&o=1&a=B00KMZUCVA" 
                                width="1" 
                                height="1" 
                                border="0" 
                                alt="" 
                                style={{border:'none !important', margin:'0px !important'}} />
                            </div>

                            <h3>Come check out the new <Link to='/shout'>Shout Box!</Link></h3>
                            <div className='legacy-title'>World of Anime</div>
                            <img src="/images/sasami.jpg" alt="Sasami looking ahead" />
                            <div className='legacy-title'>...is on hiatus</div>
                        </div>

                        <div className="home-content">
                        <h3>What happened to World of Anime, the social networking site?</h3>
                        <p>From 2009 - 2020, World of Anime was the place to go for anime fans to connect with each to discuss their love of the medium.  Through two (and a half) major versions of the site, anime fans everywhere could find and connect with others who share their same passion.  Now, a new version is under development, and we look forward to having you join us in the future to continue the legacy of World of Anime.</p>
                        <h3>When will the new version be available?</h3>
                        <p>It is completely unknown when the new version will be available.  It is still under development, and it will be ready when it is ready.  Keep checking the <Link to='/updates'>updates</Link> page to see what's new.</p>
                        <h3>So what can I do on this site now?</h3>
                        <p>While the new social site is under development, the best thing you can do is to shop at <a href="https://www.play-asia.com/?tagid=2013387">Play-Asia</a> using this affiliate link.</p>
                        <p>Also, please stop by the new <Link to='/shout'>Shout Box</Link> and let everyone know what's on your mind!</p>
                        <p>And of course keep checking the <Link to='/updates'>updates</Link> page to see what's new!</p>
                        </div>
                    </Fragment>
                }
        </Layout>
    )
};

Home.propTypes = {
    isAuthenticated: PropTypes.bool,
    loading: PropTypes.bool,
    getNewMembers: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading
});

export default connect(mapStateToProps, { getNewMembers })(Home);