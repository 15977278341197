export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CHECK_NOTIFICATIONS = 'CHECK_NOTIFICATIONS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const SEARCH_MEMBERS = 'SEARCH_MEMBERS';
export const CREATE_POST = 'CREATE_POST';
export const CREATE_REPLY = 'CREATE_REPLY';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const POST_ERROR = 'POST_ERROR';
export const GET_POST = 'GET_POST';
export const GET_POSTS = 'GET_POSTS';